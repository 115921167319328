<template>
    <div class="waitlist-container-form">
        <h1>PRÓXIMO CURSO EN {{ country }}</h1>
        <h4>Déjenos aquí sus datos y entrará en nuestra lista de espera. Le avisaremos cuando haya algún curso disponible en su país.</h4>
        <InputTextOutline 
            class="value-input" 
            @_emit_method_order_input="_getValueFromInputValueName"
            input_label="Nombre*"  
            input_helper="" 
            :has_focus="false"
            icon_class="name_icon_class" 
            input_key_to_save_user_values="value_input_key_to_save_user_values_name" 
            input_class_character_counter="value_input_class_character_counter_name" 
            :permission="true" 
            :icon_permission="false" 
            :character_counter_permission="false" 
            :helper_permission="false" 
            :test_field_is_numeric="false" 
            :length="32"
            computed_value=""
            :computed_value_permission="false"
            :is_input_textarea="true" />
            <InputTextOutline 
            class="value-input" 
            @_emit_method_order_input="_getValueFromInputValueEmail"
            input_label="Email*"  
            input_helper="" 
            :has_focus="false"
            icon_class="value_icon_class_email" 
            input_key_to_save_user_values="value_input_key_to_save_user_values_email" 
            input_class_character_counter="value_input_class_character_counter_email" 
            :permission="true" 
            :icon_permission="false" 
            :character_counter_permission="false" 
            :helper_permission="false" 
            :test_field_is_numeric="false" 
            :length="64"
            computed_value=""
            :computed_value_permission="false"
            :is_input_textarea="true" />
            <InputTextOutline 
            class="value-input" 
            @_emit_method_order_input="_getValueFromInputValuePhone"
            input_label="Phone*"  
            input_helper="" 
            :has_focus="false"
            icon_class="value_icon_class_phone" 
            input_key_to_save_user_values="value_input_key_to_save_user_values_phone" 
            input_class_character_counter="value_input_class_character_counter_phone" 
            :permission="true" 
            :icon_permission="false" 
            :character_counter_permission="false" 
            :helper_permission="false" 
            :test_field_is_numeric="true" 
            :length="9"
            computed_value=""
            :computed_value_permission="false"
            :is_input_textarea="true" />

            <Button id="form-waitlist-submit" class="submit-form-button" @_emit_method_button="_sendRegistryToOdoo" button_name="Enviar" button_key="button_form_submit_waitlist" />
    </div>
  
</template>
   

<script>
import InputTextOutline from "@/components/helpers/InputTextOutline.vue";
import Button from "@/components/helpers/Button";
import APICaller from "@/components/codeComponents/APICaller.js";
export default {
    mixins: [APICaller],
    name: 'FormWaitlistCourses',
    components: {
        InputTextOutline,
        Button,
    },
    data() {
      return {
        country: '',
        name: '',
        email: '',
        phone: '',
      };
    },
    computed: {
    },
    mounted() {
        this._getCountry();
    },
    methods: {
        _getCountry: function(){
            const dictCountry = {
                'argentina': 'ARGENTINA',
                'espana': 'ESPAÑA',
                'mexico': 'MÉXICO',
                'chile': 'CHILE'
            }

            this.country = dictCountry[this.$route.params.country];
        },
        _sendRegistryToOdoo: function(){
            if(this.name == "" || this.email == "" || this.phone == ""){
                this.$vueOnToast.pop('error', "Error", "Debe rellenar todos los campos antes de continuar.");
            }else{
                var params = new URLSearchParams();         
                params.append('event_id', '640');
                params.append('name', this.name);
                params.append('email', this.email);
                params.append('phone', this.phone);
                params.append('code_recommend', this.country);
                let success = (response) => {
                    this.$vueOnToast.pop('success', "¡Enviado!", "Sus datos han sido enviados correctamente.");
                    location.reload();
                };
    
                let url = '/api/v1/waitlist/new-register-course';
                        
                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('325', 'Error enviando los datos.');
    
                this._postAPICall(url, params, successHandler, failureHandler);
            }

        },
        _getValueFromInputValueName: function(value){
            this.name = value[0];
        }, 
        _getValueFromInputValueEmail: function(value){
            this.email = value[0];
        },
        _getValueFromInputValuePhone: function(value){
            this.phone = value[0];
        }
    },
  }
</script>
   
  
<style lang="scss" scoped>
.waitlist-container-form{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.waitlist-container-form *{
    color: var(--color-text-primary);
}
.value-input{
    width: 400px;
    margin: 15px 0 15px 0;
}
.submit-form-button{
    margin-top: 15px;
}

</style>
  